const hoverHandler = (event, mainColor, shadowColor) => {
    event.target.style.color = mainColor;
    event.target.style.boxShadow = shadowColor;
    event.target.classList.remove("normal-tag");
    event.target.classList.remove("normal-tag-text");
    event.target.classList.add("selected-tag");
    event.target.classList.add("selected-tag-text");
}

const unhoverHandler = (event, secondaryColor) => {
    event.target.style.color = secondaryColor;
    event.target.style.boxShadow = "none";
    event.target.classList.add("normal-tag");
    event.target.classList.add("normal-tag-text");
    event.target.classList.remove("selected-tag");
    event.target.classList.remove("selected-tag-text");
}

const hoverSubmitHandler = (event) => {
    event.target.classList.remove("normal-button");
    event.target.classList.add("normal-button-hover");
}

const unhoverSubmitHandler = (event) => {
    event.target.classList.remove("normal-button-hover");
    event.target.classList.add("normal-button");
}

const hoverCancelHandler = (event) => {
    event.target.classList.remove("cancel-button");
    event.target.classList.add("cancel-button-hover");
}

const unhoverCancelHandler = (event) => {
    event.target.classList.remove("cancel-button-hover");
    event.target.classList.add("cancel-button");
}

const hoverDeleteHandler = (event) => {
    event.target.classList.remove("delete-button");
    event.target.classList.add("delete-button-hover");
}

const unhoverDeleteHandler = (event) => {
    event.target.classList.remove("delete-button-hover");
    event.target.classList.add("delete-button");
}

export {hoverHandler, unhoverHandler, hoverSubmitHandler, unhoverSubmitHandler, hoverCancelHandler, unhoverCancelHandler, hoverDeleteHandler, unhoverDeleteHandler};