import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu,DropdownItem} from "reactstrap";
import spUserRoutes from "./SpUserRoutes";
import languages from "../internationalization/languages";
import i18next from "i18next";
import "./Navbar.css";
import Error1 from "../errorManip/Error1";

const Navbar = ({translator, deco}) => {
    const [spHashId, setSpHashId] = useState(null);
    const [routes] = useState(spUserRoutes);
    const [languageSelectionOpened, setLanguageSelectionOpened] = useState(false);

    const hoverHandler = (event) => {
        event.target.style.color = deco.mainColor;
    }

    const unhoverHandler = (event) => {
        event.target.style.color = deco.secondaryColor;
    }

    const logoutHandler = () => {
        sessionStorage.removeItem("user");
        window.location.assign("/");
    }

    const languageSelectionHandler = () => {
        setLanguageSelectionOpened(!languageSelectionOpened);
    }

    useEffect(() => {
        const currentPath = window.location.pathname;
        const curretnPathArray = currentPath.split("/");
        if(curretnPathArray.length >= 2){
            const spHashIdValue = curretnPathArray[1];
            setSpHashId(spHashIdValue);
        }
    }, [])


    const activePageHandle = (path) => {
        if(spHashId!==null){
            const currentPath = window.location.pathname;
            const defaultPath = "/" + spHashId;
            if(currentPath===defaultPath){
                return "/offers"
            }
            if(currentPath.includes(path)){
                return path;
            }
        } else {
            return <Error1 translator={translator} />
        }
    }

    const activeLink = (id, route, routeName) => {
        const uri = "/" + spHashId + route;
        return(
            <div key={id} className="navbar-link" style={{color: deco.mainColor}}>
                <Link style={{ textDecoration: "none" }} to={uri}>
                    {translator(routeName)}
                </Link>
            </div>
        )
    }

    const normalLink = (id, route, routeName) => {
        const uri = "/" + spHashId + route;
        return(
            <div key={id} className="navbar-link" style={{color: deco.secondaryColor}}>
                <Link style={{textDecoration: "none"}} onMouseEnter={e=>hoverHandler(e)} onMouseLeave={e=>unhoverHandler(e)} to={uri}>
                    {translator(routeName)}
                </Link>
            </div>
        )
    }

    return (
        <div className="navbar">
            <div className="navbar-left-panel">
                <div className="navbar-co-logo">
                    <img style={{width: "104px", height: "52px"}} src={require("./logos/" + deco.logoPath)} alt="nav-logo"/>
                </div>
                <div className="navbar-links">
                    {routes.map( route => 
                        route.path===activePageHandle(route.path) ? activeLink(route.id, route.path, route.name) : normalLink(route.id, route.path, route.name)      
                    )}
                </div>
            </div>
            <div className="navbar-right-panel">
                <Nav navbar>
                    <Dropdown nav isOpen={languageSelectionOpened} toggle={languageSelectionHandler} >
                        <DropdownToggle nav>
                            <img className="navbar-language-selector" src={require("./icons/globe.png")} alt="nav-globe" />
                        </DropdownToggle>
                        <DropdownMenu end>
                            {
                                Object.keys(languages).map(
                                    language => (
                                        <DropdownItem key={language} onClick={()=>i18next.changeLanguage(language)} > 
                                            {languages[language].name} 
                                        </DropdownItem>
                                    )
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                        <Link className="nav-link btn-rotate" onClick={logoutHandler}>
                            <img className="navbar-logout" src={require("./icons/logout.png")} alt="nav-logout" />
                        </Link>
                    </NavItem>
                </Nav>
            </div>    
        </div>
    )
}

export default Navbar;