import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {getCoList, getCpList} from '../offers/OfferManip';
import {getAllUploadedDiffusion} from '../diffusions/ContentManip';
import {getSuppliedContent} from '../blockchainManip/BCManip';
import NoContent from '../components/NoContent';
import BCLoadingPage from '../components/BCLoadingPage';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import { getSpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import spUserDecoRoutes from '../components/SpUserDecoRoutes';
import Error1 from '../errorManip/Error1';

const Revenu = ({translator, filters, spHash}) => {
    const [deco, setDeco] = useState(null);
    const [revenu, setRevenu] = useState(null);
    const [cos, setCos] = useState([]);
    const [cps, setCps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableCoWidth, setTableCoWidth] = useState("");
    const [tableCpWidth, setTableCpWidth] = useState("");
    const [tableContentPriceWidth, setTableContentPriceWidth] = useState("");
    const [tableServicePriceWidth, setTableServicePriceWidth] = useState("");
    const [tableViewerWidth, setTableViewerWidth] = useState("");
    const [tableIncomeWidth, setTableIncomeWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [spNotExistError, setSpNotExistError] = useState(false);

    const completeRevenuArray = (revenuArray) => {
        for (let i = 0; i < revenuArray.length; i++) {
            var revenu = revenuArray[i];
            revenu = {
                ...revenu,
                info: [
                    {
                        label: "identifiant",
                        value: revenu.suppliedContentId
                    }
                ]
            }
            revenuArray[i] = revenu;
        }
        return revenuArray;
    }

    const setCoFilter = (revenu) => {
        var selectedCo = sessionStorage.getItem("revenuCoFilter");
        if(selectedCo!==null && selectedCo!=="all"){
            revenu = revenu.filter((r) => r.suppliedContentOwner===selectedCo);
        }
        return revenu;
    }

    const setCpFilter = (revenu) => {
        var selectedCp = sessionStorage.getItem("revenuCpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            revenu = revenu.filter((r) => r.suppliedContentContentProvider===selectedCp);
        }
        return revenu;
    }

    const countViewers = (viewersArray) => {
        viewersArray = viewersArray.filter((viewer) => viewer.viewerId!=="");
        var viewers = [...new Set(viewersArray.map(viewer => viewer.viewerId))];
        return viewers.length;
    }

    const countViews = (proofsArray) => {
        var validViews = 0;
        for (let i = 0; i < proofsArray.length; i++) {
            const proof = proofsArray[i];
            if(proof.validation==="valid"){
                validViews+=1;
            }
        }
        return validViews;
    }

    const calculateIncome = (proofsArray, servicePrice) => {
        var views = 0;
        for (let i = 0; i < proofsArray.length; i++) {
            const proof = proofsArray[i];
            if(proof.validation==="valid"){
                views += 1;
            }
        }
        const incomeValue = views * servicePrice;
        return incomeValue  + " €";
    }

    useEffect(() => {
        const init = async () => {
            const spData = await getSpByHash(spHash).then(res => res.data);
            if(spData!==undefined && spData.length!==0){
                setDeco(getDeco(spUserDecoRoutes, spData[0].sp_name));
            } else {
                setSpNotExistError(true);
            }

            const coList = await getCoList().then(res => res.data);
            setCos(coList);

            const cpList = await getCpList().then(res => res.data);
            setCps(cpList);

            var suppliedContents = await getAllUploadedDiffusion(spHash).then(res => res.data);
        
            var allSuppliedContents = [];

            for (let i = 0; i < suppliedContents.length; i++) {
                const content = suppliedContents[i];
                const contentId = content.content_unique_id;
                const cpName = content.content_provider_name;
                const contentCode = getContentCode(contentId, cpName);
                const cpCpde = getCpCode(cpName);
                var bcContent = await getSuppliedContent(contentCode, cpCpde).then(res => res.data).catch((err) => {setBcErrorOccured(true); setBcErr({name: err.name, msg: err.request.response})});
                bcContent = {...bcContent, compareDate : content.valid_time_cp_sp};
                allSuppliedContents = [...allSuppliedContents, bcContent];  
            }

            allSuppliedContents = completeRevenuArray(allSuppliedContents);
            allSuppliedContents = setCoFilter(allSuppliedContents);
            allSuppliedContents = setCpFilter(allSuppliedContents);

            allSuppliedContents.sort((contentA, contentB) => {
                var dateA = contentA.compareDate;
                var dateB = contentB.compareDate;
                return new Date(dateB) - new Date(dateA)
            })
            setRevenu(allSuppliedContents);

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name")===0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableCoWidthValue = calculateTableWidth("table-co")===0 ? "auto" : calculateTableWidth("table-co") + "px";
            const tableCpWidthValue = calculateTableWidth("table-cp")===0 ? "auto" : calculateTableWidth("table-cp") + "px";
            const tableContentPriceWidthValue = calculateTableWidth("table-content-price")===0 ? "auto" : calculateTableWidth("table-content-price") + "px";
            const tableServicePriceWidthValue = calculateTableWidth("table-service-price")===0 ? "auto" : calculateTableWidth("table-service-price") + "px";
            const tableViewerWidthValue = calculateTableWidth("table-viewer")===0 ? "auto" : calculateTableWidth("table-viewer") + "px";
            const tableIncomeWidthValue = calculateTableWidth("table-income")===0 ? "auto" : calculateTableWidth("table-income") + "px";
            if(contentHeightValue!==contentHeight ||
                tableContentNameWidthValue!==tableContentNameWidth ||
                tableCoWidthValue!==tableCoWidth ||
                tableCpWidthValue!==tableCpWidth ||
                tableContentPriceWidthValue!==tableContentPriceWidth ||
                tableServicePriceWidthValue!==tableServicePriceWidth ||
                tableViewerWidthValue!==tableViewerWidth ||
                tableIncomeWidthValue!==tableIncomeWidth){
                setContentHeight(contentHeightValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCoWidth(tableCoWidthValue);
                setTableCpWidth(tableCpWidthValue);
                setTableContentPriceWidth(tableContentPriceWidthValue);
                setTableServicePriceWidth(tableServicePriceWidthValue);
                setTableViewerWidth(tableViewerWidthValue);
                setTableIncomeWidth(tableIncomeWidthValue);
            }
        };

        init();
    }, []);

    if(revenu !== null){
        if(spNotExistError){
            return(
                <Error1 translator={translator} />
            )
        } else if(bcErrorOccured){
            return (
                <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
            )
        } else if(revenu.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cos={cos}
                                cps={cps} 
                            />
                            <div className="table-container" style={{maxHeight: contentHeight}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}>{translator("content_name")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableCoWidth}}>{translator("content_owner")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableCpWidth}}>{translator("content_provider")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableContentPriceWidth}}>{translator("content_price")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableServicePriceWidth}}>{translator("service_price")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableViewerWidth}}>{translator("viewer")  + "/" + translator("views")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableIncomeWidth}}>{translator("income")}</th>
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {revenu.map((revenu, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-content-name">{revenu.suppliedContentName}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-co">{revenu.suppliedContentOwner}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-cp">{revenu.suppliedContentContentProvider}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-content-price">{revenu.suppliedContentPrice + "€"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-service-price">{revenu.servicePrice + "€"}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-viewer">{countViewers(revenu.viewerSubscription)  + "/" + countViews(revenu.viewershipVerification)}</p></td>
                                                <td style={{color: deco.secondaryColor}}><p style={{margin: 0, width: "max-content"}} className="table-income">{calculateIncome(revenu.viewershipVerification, revenu.servicePrice)}</p></td>
                                                <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{visibility: "hidden"}}><InfoPopUp infos={revenu.info} /> </span>
                                                    <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(revenu.length===0){
            return (
                <div className="wrapper">
                     <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                cos={cos}
                                cps={cps} 
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            ) 
        }
    } else if(revenu === null){
        return (
            <BCLoadingPage translator={translator} />
        )
    }
}

export default Revenu;