const calculateContainerHeight = () => {
    const container = document.getElementsByClassName("wrapper")[0];
    const header = document.getElementsByClassName("navbar")[0];
	const footer = document.getElementsByClassName("footer")[0];
    if(container !== undefined && header!==undefined && footer !== undefined){
		const paddingHeaderContainer = 164 - header.offsetHeight;
        const height = container.offsetHeight - header.offsetHeight - paddingHeaderContainer - footer.offsetHeight;
        return height + "px";
    }
}

const calculateTableWidth = (columnName) => {
    const query = "." + columnName;
    const columnItems = document.querySelectorAll([query]);
    
    var maxWidth = 0;
    for (let i = 0; i < columnItems.length; i++) {
        const item = columnItems[i];
        if(item.offsetWidth > maxWidth){
            maxWidth = item.offsetWidth;
        }
    }
    return maxWidth;
}

export {calculateContainerHeight, calculateTableWidth};