import React from "react";
import "./InfoPopUp.css";

const InfoPopUp = ({infos}) =>  {
    return(
        <div className="info-pop-up">
            {
                infos.map(
                    (info, id) => (
                        <div key={id} className="info-pop-up-item">
                          <div className="info-pop-up-title">{info.label}</div>
                          <div className="info-pop-up-text">{info.value}</div>
                        </div>
                    )
                )
            }
        </div>
    )
}

export default InfoPopUp;