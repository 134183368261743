import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import emailjs from "@emailjs/browser";
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {getSpOffer, getSuccessionNegotiation, getContentStateId, acceptOffer, updateContentState} from './OfferManip';
import {hoverDeleteHandler, unhoverDeleteHandler} from '../components/ButtonManip';
import {calculateContainerHeight} from '../components/ContainerDimensionManip';
import {getAvailableContent} from '../blockchainManip/BCManip';
import LoadingButton from "../components/LoadingButton";
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import LoadingPage from '../components/LoadingPage';
import { getSpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import spUserDecoRoutes from '../components/SpUserDecoRoutes';

const CheckOfferState = ({translator, filters, spHash}) => {
    const [deco, setDeco] = useState(null);
    const [offer, setOffer] = useState(null);
    const [successionNegotiation, setSuccessionNegotiation] = useState(null);
    const [acceptPopUpOpened, setAcceptPopUpOpened] = useState(false);
    const [cpValidity, setCpValidity] = useState(false);
    const [cpValidityMsg, setCpValidityMsg] = useState("");
    const [showEmailHanderLoader, setShowEmailHandlerLoader] = useState(false);
    const [showGetContentLoader, setShowGetContentLoader] = useState(false);
    const [contentHeight, setContentHeight] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [spNotExistError, setSpNotExistError] = useState(false);
    const n7EmailServerId = "service_owgdw6k";
    const emailTemplate = "template_2bouk8y";
    const emailJsPK = "wRBpzsiStO_gHJ0nP";


    useEffect(() => {
        const init = async () => {
            const spData = await getSpByHash(spHash).then(res => res.data);
            if(spData!==undefined && spData.length!==0){
                setDeco(getDeco(spUserDecoRoutes, spData[0].sp_name));
            } else {
                setSpNotExistError(true);
            }

            const uri = window.location.pathname;
            const uriArray = uri.split("/");
            const negotiationId = uriArray[uriArray.length - 2];
            const offerData = await getSpOffer(negotiationId).then(res => res.data);
            setOffer(offerData.length===0 ? offerData : offerData[0]);

            var successionNegotiationData = {};
            if(offerData.length!==0){
                successionNegotiationData = await getSuccessionNegotiation(offerData[0].succession_negotiation).then(res => res.data[0]);
            }
            setSuccessionNegotiation(successionNegotiationData);

            const contentHeightValue = calculateContainerHeight();
            if(contentHeightValue!==contentHeight){
                setContentHeight(contentHeightValue);
            }
        };
  
        init();
    }, []);

    const tagContainerStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "valid-tag";

            case "sp_accepted":
                return "valid-tag"
        
            default:
                return "info-tag";
        }
    }

    const tagTextStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "valid-tag-text";

            case "sp_accepted":
                return "valid-tag-text"
        
            default:
                return "info-tag-text";
        }
    }

    const servicePriceHandler = (valid, maxMark, sliderMarks, sliderBorderColor, sliderShadowColor) => {
        return(
            <div className="form-horizon-sub-item">
                <div className="form-text form-disable-text" style={{color: deco.mainColor}}>
                    {offer.service_price + "€"}
                </div>
            </div>
        )
    }

    const getContentStateIdHandler = async (offerId) => {
        return await getContentStateId(offerId).then(res => res.data[0].content_state_sp_id);
    }

    const setUTCDate = (date) => {
        const dateFormatDate = new Date(date);
        return Date.UTC(dateFormatDate.getUTCFullYear(), 
                        dateFormatDate.getUTCMonth(), 
                        dateFormatDate.getUTCDate(), 
                        dateFormatDate.getUTCHours(),
                        dateFormatDate.getUTCMinutes(),
                        dateFormatDate.getUTCSeconds());
    }

    const acceptOfferHandler = async (offerId) => {
        setShowEmailHandlerLoader(true);

        const validTime = new Date();
        const validUTCTime = new Date(setUTCDate(validTime)).toISOString();
        
        const offerData = {
            validTime: validUTCTime,
            offerId : offerId
        }
        
        const contentStateId = await getContentStateIdHandler(successionNegotiation.negotiation_id);
        const serviceStartDateString = new Date(offer.service_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"));
        const serviceEndDateString = new Date(offer.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))
        acceptOffer(offerData).then(() => {
            let contentState = {
                contentStateId: contentStateId,
                serviceProvider: offer.service_provider,
                cpSpNegotiation: offer.negotiation_id
            }
            updateContentState(contentState).then(() => {
                emailjs.send(n7EmailServerId, emailTemplate, {
                    to_email: offer.notification_email,
                    mail_subject: translator("new_available_content"),
                    start_greetings: translator("hello"),
                    message: translator("new_content") + " " + offer.content_name + " " + translator("is_available") + translator("from") + " " + serviceStartDateString + " " + translator("to") + " " + serviceEndDateString,
                    end_greetings: translator("best_regards"),
                    team_n7: translator("team_n7")
                }, emailJsPK).then(() => {
                    setShowEmailHandlerLoader(false);
                    alert(translator("proposal_accepted"));
                    window.location.assign("/" + spHash +"/offers")
                }).catch((error) => {
                    alert(translator("action_failed"))
                });
            }).catch(() => {
                alert(translator("action_failed"))
            })
        }).catch(() => {
            alert(translator("action_failed"))
        })
    }

    const verifyCp = async () => {
        const contentId = offer.content_unique_id;
        const cp = offer.content_provider_name;
        const contentCode = getContentCode(contentId, cp);
        const cpCode = getCpCode(cp);
        const bcContent = await getAvailableContent(contentCode, cpCode).then(res => res.data).catch((err) => {setBcErrorOccured(true);setBcErr({name: err.name, msg: err.request.response})});
        
        if(bcContent!==undefined){
            const negotiation = offer;
            const bcCp = bcContent.availableContentContentProvider;
            const bcAvailabilityStartDate = new Date(bcContent.availabilityStartDate);
            bcAvailabilityStartDate.setHours(0,0,0,0);
            const bcAvailabilityEndDate = new Date(bcContent.availabilityEndDate);
            bcAvailabilityEndDate.setHours(0,0,0,0);
            const today = new Date();
            today.setHours(0,0,0,0);
            const dbAvailabilityStartDate = new Date(successionNegotiation.availability_start_date);
            dbAvailabilityStartDate.setHours(0,0,0,0);
            const dbAvailabilityEndDate = new Date(successionNegotiation.availability_end_date);
            dbAvailabilityEndDate.setHours(0,0,0,0);
            const serviceStartDate = new Date(negotiation.service_start_date);
            serviceStartDate.setHours(0,0,0,0);
            const serviceEndDate = new Date(negotiation.service_end_date);
            serviceEndDate.setHours(0,0,0,0);
            
            const validToday = dbAvailabilityStartDate.getTime() <= today.getTime() && dbAvailabilityEndDate.getTime() >= today.getTime();
            const validServiceDates = serviceStartDate.getTime() >= dbAvailabilityStartDate.getTime() && serviceEndDate.getTime() <= dbAvailabilityEndDate.getTime();
            const validBCAvailabilityDates = dbAvailabilityStartDate.getTime() === bcAvailabilityStartDate.getTime() && dbAvailabilityEndDate.getTime() === bcAvailabilityEndDate.getTime();
            const validCp = bcCp === negotiation.content_provider_name;
            
            if(!validCp || !validBCAvailabilityDates){
                return {
                    validity: false,
                    msg: translator("invalid_are_you_sure")
                }
            } else {
                if(!validToday || !validServiceDates){
                    return{
                        validity: false,
                        msg: translator("invalid_are_you_sure")
                    }
                } else {
                    return{
                        validity: true,
                        msg: translator("cp_is_valid") + " " + translator("cp_is_allow_to_deliver_between") + " " + dbAvailabilityStartDate.toLocaleDateString("en-CA") + " " + translator("and") + " " + dbAvailabilityEndDate.toLocaleDateString("en-CA") + "."
                    }
                }
            }
        } else {
            return {
                validity: false,
                msg: translator("invalid_are_you_sure")
            }
        }
    }

    const showAcceptPopUp = async () => {
        setShowGetContentLoader(true);
        
        const validity = (await verifyCp()).validity;
        const msg = (await verifyCp()).msg;
        setCpValidity(validity);
        setCpValidityMsg(msg);
        setAcceptPopUpOpened(true);
        setShowGetContentLoader(false);
    }

    const hideAcceptPopUp = () => {
        setAcceptPopUpOpened(false);
    }

    const acceptActionHandler = (offerId) => {
        const validity = cpValidity;
        if(validity){
            return (
                <LoadingButton 
                    text={translator("understood")} 
                    onSubmit={()=>{acceptOfferHandler(offerId)}} 
                    color={deco.secondaryColor} 
                    loading={showEmailHanderLoader} 
                    disabled={showEmailHanderLoader} 
                />
            )
        } else {
            return(
                <>
                    <LoadingButton 
                        text={translator("yes")} 
                        onSubmit={()=>{acceptOfferHandler(offerId)}} 
                        color={deco.secondaryColor} 
                        loading={showEmailHanderLoader} 
                        disabled={showEmailHanderLoader} 
                    />
                    <button className="n7-button delete-button" onMouseEnter={hoverDeleteHandler} onMouseLeave={unhoverDeleteHandler} onClick={hideAcceptPopUp}>{translator("no")}</button>
                </>
            )
        }
    }

    const actionHandler = (valid, offerId) => {
        switch (valid) {
            case "":
                return (
                    <div className="form-button-panel">
                        <LoadingButton 
                            text={translator("validate_proposal")} 
                            onSubmit={showAcceptPopUp} 
                            loading={showGetContentLoader} 
                            disabled={showGetContentLoader} 
                            className="n7-button normal-button" 
                            color={deco.secondaryColor}
                        /> 
                        <Modal show={acceptPopUpOpened} onHide={hideAcceptPopUp}>
                            <Modal.Header>
                                <Modal.Title>
                                    {translator('cp_right')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{cpValidityMsg}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                {acceptActionHandler(offerId)}
                            </Modal.Footer>
                        </Modal>
                    </div> 
                )
        
            default:
                return (
                    <></>
                );
        }
    }

    if(offer !== null && successionNegotiation !== null){
        if(offer.length===0 || spNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else if(bcErrorOccured){
            return (
                <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
            )
        } else {
            const sliderBorderColor = "0 0 0 2px " + deco.mainColor;
            const sliderShadowColor = "0 0 0 4px " + deco.mainColor;
            const maxMark = successionNegotiation.max_service_price;
            const sliderMarks={
                0.01: {
                    style: {
                        left: "0%",
                        transform: "translateX(0%)",
                        color: deco.secondaryColor
                    },
                    label: "0.01€" 
                },
                [maxMark]: {
                    style: {
                        left: "100%",
                        transform: "translateX(-100%)",
                        color: deco.secondaryColor
                    }, 
                    label: maxMark + "€"
                }
            }
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                tag={offer.negotiation_valid===null ? translator("cp_offer_received") : translator(offer.negotiation_valid)} 
                                tagContainerStyle={tagContainerStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}
                                tagTextStyle={tagTextStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}
                                returnPath={"/" + spHash + "/offers"}
                                contentName={offer.content_name}
                            />
                            <div className="form-container" style={{maxHeight: contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_provider")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <button  
                                                    className="selected-tag selected-tag-text form-disable-text" 
                                                    style={{border: "none", color: deco.mainColor, boxShadow: deco.shadowColor}}
                                                    disabled
                                                >
                                                    {offer.content_provider_name}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_price")}
                                            </div>
                                            {servicePriceHandler(offer.negotiation_valid, maxMark, sliderMarks, sliderBorderColor, sliderShadowColor)}
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_start_date")}
                                            </div>
                                            <div className="form-horizon-sub-item form-disable-text">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {new Date(offer.service_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_end_date")}
                                            </div>
                                            <div className="form-horizon-sub-item form-disable-text">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    {new Date(offer.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {offer.content_unique_id}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_owner")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {offer.content_owner_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_start_date")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(successionNegotiation.availability_start_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("availability_end_date")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(successionNegotiation.availability_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("send_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(successionNegotiation.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(successionNegotiation.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("received_time_cp_sp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(offer.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        {offer.negotiation_valid==="sp_accepted" ? 
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_cp_sp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(offer.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div> : <></>
                                        }
                                    </div>
                                </div>
                                {/* {messageHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)} */}
                                {actionHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid, offer.negotiation_id)}    
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }   
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default CheckOfferState;