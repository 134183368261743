const orgNameNoStyleHandler = (orgName) => {
    var orgNameArray = orgName.split(" ");
    var name = orgName;
    for (let i = 0; i < orgNameArray.length; i++) {
        var partialName = orgNameArray[i];
        partialName = partialName.toLowerCase();
        orgNameArray[i] = partialName;
    }
    name = orgNameArray.join("_");
    
    return name;
}

export default orgNameNoStyleHandler;