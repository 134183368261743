const getDeco = (spUserDecoRoutes, spName) => {
    const foundSp = spUserDecoRoutes.find(sp => sp.name === spName);

    return foundSp ? {
        logoPath : foundSp.logoPath,
        mainColor : foundSp.mainColor,
        secondaryColor : foundSp.secondaryColor,
        shadowColor : foundSp.shadowColor
    } : null;
}

export default getDeco;