import React, { useState, useEffect } from "react";
import CryptoJs from "crypto-js";
import { Link } from "react-router-dom";
import { Form, Input, Select } from "antd";
import "../components/UserLogin.css";
import {getUserInfo} from "./UserManip";
import LoginNavbar from "../components/LoginNavbar";
import LoadingButton from "../components/LoadingButton";
import {hoverSubmitHandler, unhoverSubmitHandler} from "../components/ButtonManip";
import PropTypes from 'prop-types';


const SignIn = ({translator, setUser}) => {

    const [cognitoAuthSDK, setCognitoAuthSDK] = useState(null);
    const [spUserName, setSpUserName] = useState("");
    const [spUserPwd, setSpUserPwd] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    const getId = async () => {
        return getUserInfo(spUserName).then(res => {
            if(res.data.length===0){
                throw new Error(translator("wrong_company_name_or_wrong_user_name"));
            } else {
                return res.data[0];
            }
        });
    }

    const getClientHashId = (spId) => {
        var hash = CryptoJs.SHA256(spId.toString());
        var hashHex = hash.toString(CryptoJs.enc.Hex);
        var hashId = hashHex.substring(0, 8);
        return hashId;
    }

    const onFinish = async() => {
        setShowLoader(true);
        try{
            const userInfo = await getId();
            cognitoAuthSDK.login(spUserName, spUserPwd, (error, token) => {
                console.log(error);
                if(error) {
                    alert(translator("wrong_user_name_or_wrong_pwd"));
                    setShowLoader(false);
                } else {
                    const spId = userInfo.sp_id;
                    const hashSpId = getClientHashId(spId);
                    let user = {
                        id: userInfo.sp_user_id,
                        spUserName: userInfo.sp_user_name,
                        token: token
                    }
                    setUser(user);
                    const uri = "/" + hashSpId + "/offers";
                    window.location.assign(uri);
                }
            })
        } catch(error){
            alert(error);
            window.location.reload();
        }
    }

    const spUserNameHandler = (event) => {
        setSpUserName(event.target.value);
    }
    
    const spUserPwdHandler = (event) => {
        setSpUserPwd(event.target.value);
    }

    const formVerification = () => {
        const userNameNotNull = spUserName!=="";
        const userPwdNotNull = spUserPwd!=="";
        if(userNameNotNull&&userPwdNotNull){
            return (
                <div className="login-submit-btn">
                    <LoadingButton 
                        text={translator('sign_in')} 
                        className="n7-button normal-button" 
                        onSubmit={onFinish} 
                        loading={showLoader} 
                        disabled={showLoader} 
                    />
                    <Link className="attention-tag-text" to="/sign-up">{translator("sign_up")}</Link>
                </div>
                
            )
        } else {
            return (
                <div className="login-submit-btn">
                    <button className="n7-button normal-button disabled-button" disabled>{translator("sign_in")}</button>
                    <Link className="attention-tag-text" to="/sign-up">{translator("sign_up")}</Link>
                </div>
            )
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const n7sdkInstance = window.N7SDK;
                const n7sdk = new n7sdkInstance();
                setCognitoAuthSDK(n7sdk.SpUserAuth);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="wrapper">
            <div className="navbar">
                <LoginNavbar />
            </div>
            <div className="login-container">
                <div className="login-form-container">
                    <div className="login-input-container">
                        <Form 
                            name="normal_login"
                            layout="vertical"
                            className="login-inputs"
                        >
                            <Form.Item
                                name="service_provider_user_name"
                                label={translator("user_name")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_name_msg')
                                }]}>
                                <Input 
                                    placeholder={translator('your_user_name')} 
                                    autoComplete="off"
                                    value={spUserName}
                                    onChange={spUserNameHandler}/>
                            </Form.Item>
                            <Form.Item
                                name="service_provider_user_pwd"
                                label={translator("pwd")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_pwd_msg')
                                }]}>
                                <Input.Password
                                    type="password"
                                    placeholder={translator('your_pwd')}
                                    autoComplete="off"
                                    value={spUserPwd}
                                    onChange={spUserPwdHandler}/>
                            </Form.Item>  
                        </Form>
                    </div>
                    <div className="login-submit-btn">
                        {formVerification()}
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer-left-panel">
                    <div className="footer-left-panel-about-n7">
                        <a id="about-n7-link" href="#">
                            {translator('about_n7_project')}
                        </a>
                    </div>
                    <div className="footer-left-panel-contact">
                        <a id="contact-link" href="#">
                            {translator('contact_n7')}
                        </a>
                    </div>
                </div>
                <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
            </div>
        </div>
    )
}

SignIn.propTypes = {
    setUser : PropTypes.func.isRequired
}


export default SignIn;
