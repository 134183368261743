import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {getDiffusionById} from './ContentManip';
import {calculateContainerHeight} from '../components/ContainerDimensionManip';
import Error1 from '../errorManip/Error1';
import LoadingPage from '../components/LoadingPage';
import { getSpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import spUserDecoRoutes from '../components/SpUserDecoRoutes';

const CheckDiffusionState = ({translator, filters, spHash}) => {
    const [deco, setDeco] = useState(null);
    const [diffusion, setDiffusion] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [spNotExistError, setSpNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const spData = await getSpByHash(spHash).then(res => res.data);
            if(spData!==undefined && spData.length!==0){
                setDeco(getDeco(spUserDecoRoutes, spData[0].sp_name));
            } else {
                setSpNotExistError(true);
            }

            const uri = window.location.pathname;
            const uriArray = uri.split("/");
            const diffusionId = uriArray[uriArray.length - 2];
            const diffusion = await getDiffusionById(spHash, diffusionId).then(res => res.data);

            setDiffusion(diffusion.length===0 ? diffusion : diffusion[0]);

            const contentHeightValue = calculateContainerHeight();
            if(contentHeightValue!==contentHeight){
                setContentHeight(contentHeightValue);
            }
        };
        
        init();
    }, [])

    if(diffusion !== null){
        if(diffusion.length===0 || spNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else {
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator} 
                        deco={deco}
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters}
                                deco={deco} 
                                returnPath={"/" + spHash + "/diffusions"}
                                contentName={diffusion.content_name}
                            />
                            <div className="form-container" style={{maxHeight: contentHeight}}>
                                <div className="form-text-panel">
                                    <div className="form-main-panel">
                                    <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_type")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>{diffusion.content_type}</div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_price")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>{diffusion.content_price + " €"}</div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_price")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>{diffusion.service_price + " €"}</div>
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <div className="form-label" style={{color: deco.secondaryColor}}>
                                                {translator("broadcasting_dates")}
                                            </div>
                                            <div className="form-horizon-sub-item">
                                                <div className="form-text" style={{color: deco.mainColor}}>
                                                    { new Date(diffusion.service_start_date).toLocaleDateString(localStorage.getItem("i18nextLng")) 
                                                    + " - " + 
                                                    new Date(diffusion.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-info-panel">
                                    <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_id")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {diffusion.content_unique_id}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_owner")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {diffusion.content_owner_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("content_provider")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {diffusion.content_provider_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("service_provider")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {diffusion.service_provider_name}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("send_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(diffusion.received_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_co_cp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(diffusion.valid_time_cp_co).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("received_time_cp_sp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(diffusion.received_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                        <div className="form-info-item">
                                            <div className="form-info-label" style={{color: deco.secondaryColor}}>
                                                {translator("valid_time_cp_sp")}
                                            </div>
                                            <div className="form-info-text" style={{color: deco.secondaryColor}}>
                                                {new Date(diffusion.valid_time_cp_sp).toLocaleDateString(localStorage.getItem("i18nextLng"))}
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default CheckDiffusionState;