import {ReactComponent as Loader} from "./icons/loading.svg";
import "./LoadingButton.css"
import {hoverSubmitHandler, unhoverSubmitHandler} from "./ButtonManip"

const LoadingButton = ({onSubmit, text, loading, disabled, color}) => {
    return (
        <button className="n7-button normal-button submit-button" style={{color: color}} onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler} onClick={onSubmit} disabled={disabled}>
            {!loading ? text : <Loader className="spinner" style={{background:"unset"}}/>}
        </button>
    )
}

export default LoadingButton;