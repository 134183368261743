const spUserDecoRoutes = [
    {
        id: 1,
        name: "Amazon",
        logoPath: "amazon.png",
        mainColor: "#FF9900",
        secondaryColor: "#221F1F",
        shadowColor: "0px 0px 6px #FF99004D"
    },
    {
        id: 2,
        name: "Sport All",
        logoPath: "sportall.png",
        mainColor: "#346697",
        secondaryColor: "#453a36",
        shadowColor: "0px 0px 6px #3466974D"
    }
];
export default spUserDecoRoutes;