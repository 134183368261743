import React, { useState, useEffect } from "react";

const CoFilter = ({translator, deco, activeFilterGeneral, activeFilterHandler, activeFilter, deactiveFilter, hoverHandler, unhoverHandler, cos, selectCo, getCoFilterPage}) => {

    const [coList, setCoList] = useState([]);

    useEffect(() => {
        const coNames = cos.map(co => co.co_name);
        setCoList(["all", ...coNames]);
    }, [cos]);

    return (
        <div className="sidebar-filter">
            <div className="sidebar-filter-title" style={{color: deco.secondaryColor}}>
                {translator("content_owner_full")}
            </div>
            <div className="sidebar-filter-container">
                {
                    coList.map(
                        co => (
                            activeFilterHandler("co", co) ? activeFilter("co", co) : deactiveFilter("co", co)
                        )
                    )
                }
            </div>
        </div>
    )
}

export default CoFilter; 