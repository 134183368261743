import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import getDeco from '../components/DecoManip';
import spUserDecoRoutes from "../components/SpUserDecoRoutes";
import {getSpOffer, getAllSpOffers, getCoList, getCpList} from './OfferManip';
import {hoverSubmitHandler, unhoverSubmitHandler} from '../components/ButtonManip';
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import NoContent from '../components/NoContent';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import LoadingPage from '../components/LoadingPage';
import { getSpByHash } from '../userLogin/UserManip';
import Error1 from '../errorManip/Error1';

const Offers = ({translator, filters, spHash}) => {
    const [deco, setDeco] = useState(null);
    const [offers, setOffers] = useState(null);
    const [cos, setCos] = useState([]);
    const [cps, setCps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableStateWidth, setTableStateWidth] = useState("");
    const [tableCoWidth, setTableCoWidth] = useState("");
    const [tableCpWidth, setTableCpWidth] = useState("");
    const [spNotExistError, setSpNotExistError] = useState(false);

    const tagContainerStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "valid-tag table-state";

            case "sp_accepted":
                return "valid-tag table-state"
        
            default:
                return "info-tag table-state";
        }
    }

    const tagTextStyleHandler = (valid) => {
        switch (valid) {
            case "":
                return "valid-tag-text";

            case "sp_accepted":
                return "valid-tag-text"
        
            default:
                return "info-tag-text";
        }
    }

    const completeOfferArray = (offerArray) => {
        for (let i = 0; i < offerArray.length; i++) {
            var offer = offerArray[i];
            offer = {
                ...offer,
                info: [
                    {
                        label: "identifiant",
                        value: offer.content_unique_id
                    }
                ]
            }
            offerArray[i] = offer;
        }
        return offerArray;
    }

    const setCoFilter = (offers, coList) => {
        var selectedCo = sessionStorage.getItem("offerCoFilter");
        if(selectedCo!==null && selectedCo!=="all"){
            offers = offers.filter((offer) => coList.find(co => co.co_id===offer.content_owner).co_name===selectedCo);
        }
        return offers;
    }

    const setCpFilter = (offers, cpList) => {
        var selectedCp = sessionStorage.getItem("offerCpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            offers = offers.filter((offer) => cpList.find(cp => cp.cp_id===offer.content_provider).cp_name===selectedCp);
        }
        return offers;
    }

    const getCorrespondStateFilterId = (state) => {
        return state===null ? "cp_offer_received" : state;
    }

    const setStateFilter = (offers) => {
        var selectedState = sessionStorage.getItem("offerStateFilter");
        if(selectedState!==null && selectedState!=="all"){
            offers = offers.filter((offer) => getCorrespondStateFilterId(offer.negotiation_valid)===selectedState);
        }
        return offers;
    }

    const completeOfferArrayForDateComparison = async (offers) => {
        for (let i = 0; i < offers.length; i++) {
            var offer = offers[i];
            var compareDate = offer.received_time_cp_sp;
            
            if(offer.negotiate_on!==null){
                const negotiateON = await getSpOffer(offer.negotiate_on).then(res => res.data[0]);
                compareDate = negotiateON.received_time_cp_sp;
            }

            offer = {
                ...offer,
                compareDate: compareDate
            }
            offers[i] = offer;
        }
        return offers
    }

    useEffect(() => {
        const init = async () => {
            const spData = await getSpByHash(spHash).then(res => res.data);
            if(spData!==undefined && spData.length!==0){
                setDeco(getDeco(spUserDecoRoutes, spData[0].sp_name));
            } else {
                setSpNotExistError(true);
            }

            var spOffers = await getAllSpOffers(spHash).then(res => res.data);
            spOffers = completeOfferArray(spOffers);
    
            const coList = await getCoList().then(res => res.data);
            setCos(coList);
            
            const cpList = await getCpList().then(res => res.data);
            setCps(cpList);
    
            spOffers = setCoFilter(spOffers, coList);
            spOffers = setCpFilter(spOffers, cpList);
            spOffers = setStateFilter(spOffers);
            spOffers = await completeOfferArrayForDateComparison(spOffers);
    
            spOffers.sort((offerA, offerB) => {
                var dateA = offerA.compareDate;
                var dateB = offerB.compareDate;
                return new Date(dateB) - new Date(dateA)
            })
            setOffers(spOffers);

            const contentHeightValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name")===0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableStateWidthValue = calculateTableWidth("table-state")===0 ? "auto" : calculateTableWidth("table-state") + "px";
            const tableCoWidthValue = calculateTableWidth("table-co")===0 ? "auto" : calculateTableWidth("table-co") + "px";
            const tableCpWidthValue = calculateTableWidth("table-cp")===0 ? "auto" : calculateTableWidth("table-cp") + "px";
            if(contentHeightValue!==contentHeight || tableContentNameWidthValue!==tableContentNameWidth || tableCoWidthValue!==tableCoWidth || tableCpWidthValue!==tableCpWidth || tableStateWidthValue!==tableStateWidth){
                setContentHeight(contentHeightValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCoWidth(tableCoWidthValue);
                setTableCpWidth(tableCpWidthValue);
                setTableStateWidth(tableStateWidthValue);
            }
        };

        init();
    }, [])
    
    if(offers !== null){
        if(spNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(offers.length!==0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cos={cos}
                                    cps={cps} 
                                />
                                <div className="table-container" style={{maxHeight: contentHeight}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}>{translator("content_name")}</th>
                                                <th style={{color: deco.secondaryColor, width: tableStateWidth}}><p style={{margin: 0, width: "max-content"}}>{translator("negotiation_state")}</p></th>
                                                <th style={{color: deco.secondaryColor, width: tableCoWidth}}>{translator("content_owner")}</th>
                                                <th style={{color: deco.secondaryColor, width: tableCpWidth}}>{translator("content_provider")}</th>
                                                <th style={{color: deco.secondaryColor}}>{translator("action")}</th>      
                                                <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {offers.map((offer, id) => 
                                                <tr key = {id} >
                                                    <td style={{color: deco.secondaryColor}}><p className="table-content-name" style={{margin:0, width: "max-content"}}>{offer.content_name}</p></td>
                                                    <td>
                                                        <div className={tagContainerStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}>
                                                            <div className={tagTextStyleHandler(offer.negotiation_valid===null ? "" : offer.negotiation_valid)}>
                                                                {offer.negotiation_valid===null ? translator("cp_offer_received") : translator(offer.negotiation_valid)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-co" style={{margin:0, width: "max-content"}}>{cos.find(co => co.co_id===offer.content_owner).co_name}</p></td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-cp" style={{margin:0, width: "max-content"}}>{cps.find(cp => cp.cp_id===offer.content_provider).cp_name}</p></td>
                                                    <td style={{whiteSpace: "nowrap"}}>
                                                        <Link to={"/" + spHash + "/offers/" + offer.negotiation_id + "/checkOffer"}>
                                                            <input type='button' className="table-action-buttons n7-button normal-button" onMouseEnter={e=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} value={translator("get_cp_sp_negotiation")} style={{color: deco.secondaryColor}}/>
                                                        </Link>
                                                    </td>
                                                    <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                        <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={offer.info} /> </span>
                                                        <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } else if(offers.length===0){
                return (
                    <div className="wrapper">
                         <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco}
                                    cos={cos}
                                    cps={cps} 
                                />
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            }
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default Offers;