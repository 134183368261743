import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import {hoverSubmitHandler, unhoverSubmitHandler} from '../components/ButtonManip';
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {getAllDiffusion} from './ContentManip';
import {getCoList, getCpList} from '../offers/OfferManip';
import NoContent from '../components/NoContent';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import LoadingPage from '../components/LoadingPage';
import { getSpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import spUserDecoRoutes from '../components/SpUserDecoRoutes';
import Error1 from '../errorManip/Error1';

const Diffusions = ({translator, filters, spHash}) => {
    const [deco, setDeco] = useState(null);
    const [diffusions, setDiffusions] = useState(null);
    const [cos, setCos] = useState([]);
    const [cps, setCps] = useState([]);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableCoWidth, setTableCoWidth] = useState("");
    const [tableCpWidth, setTableCpWidth] = useState("");
    const [tableDatesWidth, setTableDatesWidth] = useState("");
    const [spNotExistError, setSpNotExistError] = useState(false);

    const completeDiffusionArray = (diffusionArray) => {
        for (let i = 0; i < diffusionArray.length; i++) {
            var diffusion = diffusionArray[i];
            diffusion = {
                ...diffusion,
                info: [
                    {
                        label: "identifiant",
                        value: diffusion.content_unique_id
                    }
                ]
            }
            diffusionArray[i] = diffusion;
        }
        return diffusionArray;
    }

    const setCoFilter = (diffusions, coList) => {
        var selectedCo = sessionStorage.getItem("diffusionCoFilter");
        if(selectedCo!==null && selectedCo!=="all"){
            diffusions = diffusions.filter((diffusion) => coList.find(co => co.co_id===diffusion.content_owner).co_name===selectedCo);
        }
        return diffusions;
    }

    const setCpFilter = (diffusions, cpList) => {
        var selectedCp = sessionStorage.getItem("diffusionCpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            diffusions = diffusions.filter((diffusion) => cpList.find(cp => cp.cp_id===diffusion.content_provider).cp_name===selectedCp);
        }
        return diffusions;
    }

    const completeOfferArrayForDateComparison = (diffusions) => {
        for (let i = 0; i < diffusions.length; i++) {
            var diffusion = diffusions[i];
            var compareDate = diffusion.valid_time_cp_sp;

            diffusion = {
                ...diffusion,
                compareDate: compareDate
            }
            diffusions[i] = diffusion;
        }
        return diffusions;
    }

    useEffect(() => {
        const init = async () => {
            const spData = await getSpByHash(spHash).then(res => res.data);
            if(spData!==undefined && spData.length!==0){
                setDeco(getDeco(spUserDecoRoutes, spData[0].sp_name));
            } else {
                setSpNotExistError(true);
            }

            var diffusions = await getAllDiffusion(spHash).then(res => res.data);
            diffusions = completeDiffusionArray(diffusions);

            const coList = await getCoList().then(res => res.data);
            setCos(coList);

            const cpList = await getCpList().then(res => res.data);
            setCps(cpList);

            diffusions = setCoFilter(diffusions, coList);
            diffusions = setCpFilter(diffusions, cpList);
            diffusions = completeOfferArrayForDateComparison(diffusions);
            diffusions.sort((diffA, diffB) => {
                var dateA = diffA.compareDate;
                var dateB = diffB.compareDate;
                return new Date(dateB) - new Date(dateA)
            })

            setDiffusions(diffusions);

            const contentHeigthValue = calculateContainerHeight();
            const tableContentNameWidthValue = calculateTableWidth("table-content-name")===0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const tableCoWidthValue = calculateTableWidth("table-co")===0 ? "auto" : calculateTableWidth("table-co") + "px";
            const tableCpWidthValue = calculateTableWidth("table-cp")===0 ? "auto" : calculateTableWidth("table-cp") + "px";
            const tableDatesWidthValue = calculateTableWidth("table-dates")===0 ? "auto" : calculateTableWidth("table-dates") + "px";
            if(contentHeigthValue!==contentHeight || tableContentNameWidthValue!==tableContentNameWidth || tableCpWidthValue!==tableCpWidth || tableCoWidthValue!==tableCoWidth || tableDatesWidthValue!==tableDatesWidth){
                setContentHeight(contentHeigthValue);
                setTableContentNameWidth(tableContentNameWidthValue);
                setTableCoWidth(tableCoWidthValue);
                setTableCpWidth(tableCpWidthValue);
                setTableDatesWidth(tableDatesWidthValue);
            }
        }

        init();
    }, []);

    const diffusionAvailabilityTag = (serviceStartDate, serviceEndDate) => {
        var today = new Date();
        today = today.setHours(0,0,0,0);
        var diffusionStartDate = new Date(serviceStartDate);
        diffusionStartDate.setHours(0,0,0,0);
        var diffusionEndDate = new Date(serviceEndDate);
        diffusionEndDate.setHours(0,0,0,0);
        if(today < diffusionStartDate || today > diffusionEndDate){
            return (
                <div className="info-tag info-tag-text">
                    <p className="table-dates" style={{margin: 0, width: "max-content"}}>
                        {
                            new Date(serviceStartDate).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                            " - " +
                            new Date(serviceEndDate).toLocaleDateString(localStorage.getItem("i18nextLng"))
                        }
                    </p>
                </div>
            )
        } else {
            return (
                <div className="valid-tag valid-tag-text">
                    <p className="table-dates" style={{margin: 0, width: "max-content"}}>
                        {
                            new Date(serviceStartDate).toLocaleDateString(localStorage.getItem("i18nextLng")) + 
                            " - " +
                            new Date(serviceEndDate).toLocaleDateString(localStorage.getItem("i18nextLng"))
                        }
                    </p>
                </div>
            )
        }
    }
    
    if(diffusions !== null){
        if(spNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(diffusions.length!==0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco} 
                                    cos={cos}
                                    cps={cps} 
                                />
                                <div className="table-container">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{color: deco.secondaryColor, width: tableContentNameWidth}}>{translator("content_name")}</th>
                                                <th style={{color: deco.secondaryColor, width: tableCoWidth}}>{translator("content_owner")}</th>
                                                <th style={{color: deco.secondaryColor, width: tableCpWidth}}>{translator("content_provider")}</th>
                                                <th style={{color: deco.secondaryColor, width: tableDatesWidth}}><p style={{ margin: 0, width: "max-content"}}>{translator("broadcasting_dates")}</p></th>
                                                <th style={{color: deco.secondaryColor}}>{translator("action")}</th>      
                                                <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                      
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {diffusions.map((diffusion, id) => 
                                                <tr key = {id} >
                                                    <td style={{color: deco.secondaryColor}}><p className="table-content-name" style={{margin: 0, width: "max-content"}}>{diffusion.content_name}</p></td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-co" style={{margin: 0, width: "max-content"}}>{cos.find(co => co.co_id===diffusion.content_owner).co_name}</p></td>
                                                    <td style={{color: deco.secondaryColor}}><p className="table-cp" style={{margin: 0, width: "max-content"}}>{cps.find(cp => cp.cp_id===diffusion.content_provider).cp_name}</p></td>
                                                    <td style={{color: deco.secondaryColor, paddingRight: "85px"}}> 
                                                        {diffusionAvailabilityTag(diffusion.service_start_date, diffusion.service_end_date)}
                                                    </td>
                                                    <td style={{color: deco.secondaryColor, whiteSpace: "nowrap"}}>                                               
                                                        <Link to={"/" + spHash + "/diffusions/" + diffusion.content_state_sp_id + "/viewership"}>
                                                            <input 
                                                                type="button"
                                                                className="table-action-buttons n7-button normal-button" 
                                                                onMouseEnter={e=>hoverSubmitHandler(e)}
                                                                onMouseLeave={e=>unhoverSubmitHandler(e)}
                                                                value={translator("check_viewership")} 
                                                                style={{color: deco.secondaryColor}}/>
                                                        </Link>
                                                        <Link to={"/" + spHash + "/diffusions/" + diffusion.content_state_sp_id + "/checkInfo"}>
                                                            <input 
                                                                type="button"
                                                                className="table-action-buttons n7-button normal-button" 
                                                                onMouseEnter={e=>hoverSubmitHandler(e)}
                                                                onMouseLeave={e=>unhoverSubmitHandler(e)}
                                                                value={translator("check_proposal")} 
                                                                style={{color: deco.secondaryColor}}/>
                                                        </Link>
                                                    </td>
                                                    <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right", color: deco.secondaryColor}}>
                                                        <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={diffusion.info} /> </span>
                                                        <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            } else if(diffusions!==null && diffusions.length===0){
                return (
                    <div className="wrapper">
                         <Navbar 
                            translator={translator}
                            deco={deco} 
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator} 
                                    filters={filters} 
                                    deco={deco} 
                                    cos={cos}
                                    cps={cps} 
                                />
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} deco={deco} />
                        </div>
                    </div>
                )
            }
        }
    } else {
        return <LoadingPage translator={translator} />
    }
}

export default Diffusions;