import axios from "axios";

const BASIC_URL="https://n7-demo-service-provider.nseven.xyz/api/v1/sp";

const getAllDiffusion = (spHash) => { 
    const url=BASIC_URL + "/diffusions/" + spHash + "/all";
    return axios.get(url);
}

const getAllUploadedDiffusion = (spHash) => {
    const url=BASIC_URL + "/diffusions/" + spHash + "/uploaded";
    return axios.get(url);
}

const getDiffusionById = (spHash, diffusionId) => {
    const url=BASIC_URL + "/diffusions/" + spHash + "/contentState/" + diffusionId;
    return axios.get(url);
}

export {getAllDiffusion, getAllUploadedDiffusion, getDiffusionById};
