import React from "react";

const StateFilter = ({translator, deco, activeFilterGeneral, activeFilterHandler, activeFilter, deactiveFilter, hoverHandler, unhoverHandler, selectState}) => {

    const stateList = ["all", "cp_offer_received", "sp_negotiating", "sp_accepted"];

    return (
        <div className="sidebar-filter">
            <div className="sidebar-filter-title" style={{color: deco.secondaryColor}}>
                {translator("state")}
            </div>
            <div className="sidebar-filter-container">
                {
                    stateList.map(
                        state => (
                            activeFilterHandler("state", state) ? activeFilter("state", state) : deactiveFilter("state", state)
                        )
                    )
                }
            </div>
        </div>
    )
}

export default StateFilter;