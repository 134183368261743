import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import InfoPopUp from '../components/InfoPopUp';
import "../components/MainPanel.css";
import {showInfoPopUp, hideInfoPopUp} from '../components/PopUpManip';
import {getDiffusionById} from './ContentManip';
import {getSuppliedContent} from '../blockchainManip/BCManip';
import BCLoadingPage from '../components/BCLoadingPage';
import NoContent from '../components/NoContent';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import {getContentCode, getCpCode} from '../blockchainManip/CpCode';
import { getSpByHash } from '../userLogin/UserManip';
import getDeco from '../components/DecoManip';
import spUserDecoRoutes from '../components/SpUserDecoRoutes';

const CheckViewership = ({translator, filters, spHash}) => {
    const [deco, setDeco] = useState(null);
    const [viewers, setViewers] = useState(null);
    const [contentState, setContentState] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [tableViewerWidth, setTableViewerWidth] = useState("");
    const [tableProofWidth, setTableProofWidth] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [spNotExistError, setSpNotExistError] = useState(false);

    const completeInfoArray = (contentState, viewerArray) => {
        for (let i = 0; i < viewerArray.length; i++) {
            var viewer = viewerArray[i];
            viewer = {
                ...viewer,
                info: [
                    {
                        label: "identifiant",
                        value: contentState.content_unique_id
                    }
                ]
            }
            viewerArray[i] = viewer;
        }
        return viewerArray;
    }

    useEffect(() => {
        const init = async () => {
            const spData = await getSpByHash(spHash).then(res => res.data);
            if(spData!==undefined && spData.length!==0){
                setDeco(getDeco(spUserDecoRoutes, spData[0].sp_name));
            } else {
                setSpNotExistError(true);
            }

            const uri = window.location.pathname;
            const uriArray = uri.split("/");
            const contentStateId = uriArray[uriArray.length - 2];
            const contentState = await getDiffusionById(spHash, contentStateId).then(res => res.data);

            var viewers = [];

            if(contentState.length!==0){
                if(contentState[0].uploaded_to_bc===1){
                    const contentId = getContentCode(contentState[0].content_unique_id, contentState[0].content_provider_name);
                    const cp = getCpCode(contentState[0].content_provider_name);
                    const suppliedContent = await getSuppliedContent(contentId, cp).then(res => res.data).catch((err)=>{setBcErrorOccured(true); setBcErr({name: err.name, msg: err.request.response})});
                    
                    if(suppliedContent !== undefined){
                        viewers = suppliedContent.viewershipVerification;
                        viewers = viewers.filter(viewer => viewer.viewer !== "")
                        viewers = completeInfoArray(contentState, viewers);
                    }
                }
            }

            setViewers(viewers);
            setContentState(contentState.length===0 ?  contentState : contentState[0]);

            const contentHeightValue = calculateContainerHeight();
            const tableViewerWidthValue = calculateTableWidth("table-viewer")===0 ? "auto" : calculateTableWidth("table-viewer") + "px";
            const tableProofWidthValue = calculateTableWidth("table-proof")===0 ? "auto" : calculateTableWidth("table-proof") + "px";
            if(contentHeightValue!==contentHeight || tableViewerWidthValue!==tableViewerWidth || tableProofWidthValue!==tableProofWidth){
                setContentHeight(contentHeightValue);
                setTableViewerWidth(tableViewerWidthValue);
                setTableProofWidth(tableProofWidthValue);
            }
        };

        init();
    }, []);

    const proofVerifiedTag = () => {
        return(
            <div className="valid-tag table-proof">
                <div className="valid-tag-text">
                    {translator("proof_verified")}
                </div>
            </div>
        )
    }

    const proofNotVerifiedTag = () => {
        return(
            <div className="attention-tag table-proof">
                <div className="attention-tag-text">
                    {translator("proof_not_verified")}
                </div>
            </div>
        )
    }

    if(contentState !== null && viewers!==null){
        if(contentState.length===0 || spNotExistError){
            return (
                <Error1 translator={translator} />
            )
        } else if(bcErrorOccured){
            return (
                <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
            )
        } else if(viewers.length!==0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator} 
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + spHash + "/diffusions"}
                                contentName={contentState.content_name}
                                co={contentState.content_owner_name}
                                cp={contentState.content_provider_name}
                                sp={contentState.service_provider_name} 
                            />
                            <div className="table-container">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{color: deco.secondaryColor, width: tableViewerWidth}}>{translator("viewer")}</th>
                                            <th style={{color: deco.secondaryColor, width: tableProofWidth}}>{translator("proof")}</th>    
                                            <th style={{color: deco.secondaryColor, textAlign: "right", paddingRight: 0}}>{translator("information")}</th>                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewers.map((viewer, id) => 
                                            <tr key = {id} >
                                                <td style={{color: deco.secondaryColor}}><p className="table-viewer" style={{margin: 0, width: "max-content"}}>{viewer.viewer}</p></td>
                                                <td> {viewer.validation==="valid" ? proofVerifiedTag() : proofNotVerifiedTag()} </td>
                                                <td className="table-infomation-panel" style={{paddingRight: "15px", textAlign: "right"}}>
                                                    <span id={"info-pop-up-container-" + id} style={{ visibility: "hidden" }}><InfoPopUp infos={viewer.info}/> </span>
                                                    <img className="info-pop-up-img" onMouseEnter={()=>{showInfoPopUp(id)}} onMouseLeave={()=>{hideInfoPopUp(id)}} src={require("../components/icons/info.png")} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        } else if(viewers.length===0){
            return (
                <div className="wrapper">
                    <Navbar 
                        translator={translator}
                        deco={deco} 
                    />
                    <div className="main-panel-with-footer">
                        <div className="main-panel-container">
                            <Sidebar 
                                translator={translator} 
                                filters={filters} 
                                deco={deco}
                                returnPath={"/" + spHash + "/diffusions"}
                                contentName={contentState.content_name}
                                co={contentState.content_owner_name}
                                cp={contentState.content_provider_name}
                                sp={contentState.service_provider_name} 
                            />
                            <NoContent translator={translator} />
                        </div>
                        <Foot translator={translator} deco={deco} />
                    </div>
                </div>
            )
        }
    } else if(viewers===null){
        return (
            <BCLoadingPage translator={translator}/>
        )
    }
}

export default CheckViewership;